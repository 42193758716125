import React, { useEffect, useRef, useState, useContext, useLayoutEffect } from 'react';

import Pill from './pill';

import { animated, config, useSpring } from 'react-spring';
import { PageWrapperContext } from './page-wrapper';
import { AuthorsContext } from './authors';

import '../styles/components/author.scss';

const Author = ( { frontmatter } ) => {

    const { name, info, description, linkedin, twitter, instagram, facebook, website, image } = frontmatter;

    const authorRef = useRef();
    const authorsContext = useContext( AuthorsContext );
    const pageWrapperContext = useContext( PageWrapperContext );

    const [ expanded, setExpanded ] = useState( false );
    const [ offsetX, setOffsetX ] = useState( 0 );

    const { transform } = useSpring( {
        transform: `translate3d(${authorsContext.mouseX - offsetX}px,0,0)`,
        config: config.fast
    } );

    useEffect( () => {
        setOffsetX( authorRef.current.getBoundingClientRect().x );
    }, [ authorRef ] );

    const renderSocials = () => {

        const socials = [];

        if ( linkedin ) {
            socials.push( {
                name: 'LinkedIn',
                url: linkedin
            } );
        }

        if ( twitter ) {
            socials.push( {
                name: 'Twitter',
                url: twitter
            } );
        }

        if ( instagram ) {
            socials.push( {
                name: 'Instagram',
                url: instagram
            } );
        }

        if ( facebook ) {
            socials.push( {
                name: 'Facebook',
                url: facebook
            } );
        }

        if ( website ) {
            socials.push( {
                name: 'Website',
                url: website
            } );
        }

        return socials.map( ( social, index ) => {
            return <a key={index} href={social.url} target="_blank" rel="noopener noreferrer nofollow"><Pill label={social.name}></Pill></a>;
        } );

    };

    useLayoutEffect( () => {
        pageWrapperContext.setPageHeight( document.body.clientHeight );
    } );

    return <div className={`author row middle ${expanded ? 'expanded' : ''}`} ref={authorRef}>
        <div className="col-7 col-xs-12 middle name" onClick={() => { setExpanded( !expanded ); }}>
            <h3 className="name">{name}</h3>
            <span className="tip">Click for biography</span>
            {image &&
                <animated.div className="image" style={{ transform }}><img src={image} /></animated.div>
            }
        </div>
        <div className="col-4 col-xs-12 socials end start-xs"> {renderSocials()}</div>
        {expanded &&
            <div className="col-9 col-xs-12 bio">
                { info && 
                <p dangerouslySetInnerHTML={{ __html: info }}></p>
                }
                <p>{description}</p>
            </div>
        }
        <div className="col-11"><div className="line"></div></div>
    </div>;

};

export default Author;
