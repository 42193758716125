import React from 'react';

import Button from './button';

import '../styles/components/feedback.scss';

const Feedback = () => {
    return <section className="feedback container-fluid">
        <a href="mailto:playbook@n-ost.org" title="playbook@n-ost.org">
            <div className="row middle-xs">
                <div className="col-9 col-sm-12">
                    <h3>Feedback</h3>
                </div>
                <div className="col-3 col-sm-12 col-align-mid">
                    <Button>{' '}</Button>
                </div>
            </div>
        </a>
    </section>;
};

export default Feedback;
