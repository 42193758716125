import React, { useState, useEffect, useContext } from 'react';

import Feedback from '../components/feedback';
import Page from '../components/page';
import Authors from '../components/authors';
import Author from '../components/author';
import SEO from '../components/seo';

import { PageWrapperContext } from '../components/page-wrapper';
import { useData } from '../components/data';

import styles from '../styles/pages/team.module.scss';

const TeamPage = ( { transitionStatus } ) => {

    const pageWrapperContext = useContext( PageWrapperContext );

    const { authors } = useData();

    useEffect( () => {
        pageWrapperContext.setPageClass( 'page-chapters' );
    }, [] );

    const renderAuthors = () => {
        return authors.map( ( author, index ) => {
            return <Author
                key={index}
                frontmatter={author.frontmatter}
            />;
        } );
    };

    return <Page transitionStatus={transitionStatus}>
        <SEO title="Our Team" description="In assembling this toolkit, we collaborated with a group of trail-blazing journalists. Over the last years they have participated in numerous cross-border projects and/or investigations. Have a look at their profiles and to which parts of the playbook they’ve contributed." image={'/images/opengraph/general.jpg'} />
        <section className={'container-fluid'}>
            <div className={`row ${styles.title}`}>
                <div className="col-12">
                    <h1>Our Team</h1>
                </div>
            </div>
            <div className={`row ${styles.description}`}>
                <div className="col col-9 col-xs-12">
                    <p>In assembling this toolkit, we collaborated with a group of trail-blazing journalists. Over the last years they have participated in numerous cross-border projects and/or investigations. Have a look at their profiles and to which parts of the playbook they’ve contributed.</p>
                </div>
            </div>
        </section>
        <Authors>
            {renderAuthors()}
        </Authors>
        <Feedback />
    </Page>;
};

export default TeamPage;
