import React, { useState, useEffect } from 'react';

import '../styles/components/authors.scss';

const Articles = ( { children } ) => {

    const [ mouseX, setMouseX ] = useState( 0 );

    const onMouseMove = ( e ) => {
        setMouseX( e.clientX );
    };

    useEffect( () => {
        window.addEventListener( 'mousemove', onMouseMove );
        return () => {
            window.removeEventListener( 'mousemove', onMouseMove );
        };
    }, [] );

    return <section className="authors">
        <section className={'container-fluid'}>
            <AuthorsContext.Provider value={{ mouseX }}>
                {children}
            </AuthorsContext.Provider>
        </section>
    </section>;

};

export const AuthorsContext = React.createContext();
export default Articles;
